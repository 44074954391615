import { Fragment } from 'react';

import { ErrorPage } from 'src/general/components/ErrorPage/ErrorPage';
import Head from 'src/general/components/Head/Head';

export default function Page404() {
  return (
    <>
      <Head subtitle='Page Not Found' />
      <ErrorPage title="The page you're looking for can't be found." />
    </>
  );
}
